import React from "react";
import { graphql } from "gatsby";

import HeroHeading from "../components/HeroHeading";
import ViewNext from "../components/ViewNext";
import NetworksHero from "../components/NetworksHero";
import IconBox from "../components/IconBox";
import NetworksList from "../components/NetworksList";
import HelmetInfo from "../components/HelmetInfo";

const Networks = ({ data }) => {
  const {
    heading,
    subheading,
    hero_image,
    hero_logo,
    hero_text,
    infobox_heading,
    infobox_subheading,
    infobox_icons,
    networks_heading,
    networks,
  } = data.prismicNetworksIndex.data;

  return (
    <>
      <HelmetInfo page_data={data.prismicNetworksIndex.data} />
      <HeroHeading heading={heading} subheading={subheading} />
      <NetworksHero image={hero_image} logo={hero_logo} text={hero_text} />
      <IconBox
        heading={infobox_heading}
        subheading={infobox_subheading}
        icons={infobox_icons}
      />
      <NetworksList heading={networks_heading} networks={networks} />
      <ViewNext
        link={`/network/${networks[0].network.document.uid}`}
        linkText={networks[0].network.document.data.title}
        borderTop
      />
    </>
  );
};

export default Networks;

export const query = graphql`
  query NetworksIndexQuery {
    prismicNetworksIndex {
      data {
        heading
        subheading
        hero_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        hero_logo {
          alt
          copyright
          url
          gatsbyImageData
        }
        hero_text {
          text
          html
          richText
        }
        infobox_heading
        infobox_subheading
        infobox_icons {
          icon {
            alt
            copyright
            url
            gatsbyImageData
          }
          icon_description
        }
        networks_heading
        networks {
          network {
            document {
              ... on PrismicNetwork {
                uid
                data {
                  logo {
                    alt
                    url
                  }
                  title
                  short_description
                }
              }
            }
          }
        }
      }
    }
  }
`;
